<template>
  <section class="products">
    <v-img
      v-if="info.banner"
      :src="info.banner"
      class="tnf-top-banner"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
        <v-flex style="position: absolute; bottom: 40px;" class="tnf-submenu">
            <v-tabs grow light v-model="firstCategory">
              <v-tab v-for="tab of firstCategorys" :key="tab.key">
                <a :href="'/products/'+tab.id">{{tab.name}}</a>
              </v-tab>
            </v-tabs>
        </v-flex>
      </v-layout>
    </v-img>
    <v-container  grid-list-md text-center class="ctk-div"  id="ctk_top" style="padding-top:50px !important;">
      <v-layout wrap>
        <v-flex md12 lg12 xl12 mb-2 class="tnf-secondmenu" v-if="secondCategorys.length">
          <ul>
            <li v-for="tab in secondCategorys" :key="tab.key">
                <a :href="'/products/'+p1+'/'+tab.id" :class="catId==tab.id?'second-active':''">{{tab.name}}</a>
            </li>
          </ul>
        </v-flex>
        <v-flex
          xs12 sm6 md3 lg3 xl3
          mb-6
          v-for="(product, i) in products"
          :key="i"
        >
          <v-layout wrap ma-4 class="product-div">
            <v-flex xs12 sm12 md12 lg12 pa-0>
              <a :href="'/product/' + product.id">
                <v-img
                  :src="product.img"
                  aspect-ratio="1"
                  class="ctk-p-i"
                ></v-img>
              </a>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 pa-0>
              <div class="product-title">{{product.title}}</div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    breadcrumbs: [],
    p1: null,
    p2: null,
    catId: null,
    pageId: 2,
    firstCategory: null,
    secondCategory: null,
    firstCategorys: [],
    secondCategorys: [],
    firstIds: [],
    products: [],
    secondIds: [],
    totalPage: 1,
    page: 1
  }),
  created () {
    this.p1 = this.$route.params.p1
    if (this.p1 === '71') {
      window.location.href = '/product/231'
    }
    this.p2 = this.$route.params.p2
    if (this.p2) {
      this.catId = this.p2
    } else {
      this.catId = this.p1
    }
  },
  computed: {
    info () {
      let banner = null
      if (this.$store.getters.companyInfo.banner) {
        banner = this.$store.getters.companyInfo.banner[this.pageId][0].img
        let firstIds = []
        let firstCategorys = []
        let cats = this.$store.getters.companyInfo.product.category.cn
        for (let i in cats) {
          firstIds.push(cats[i].id)
          firstCategorys.push(
            { key: i, id: cats[i].id, name: cats[i].name }
          )
          this.firstCategory = firstIds.indexOf(parseInt(this.p1))
        }
        this.firstIds = firstIds
        this.firstCategorys = firstCategorys
      }
      return {
        banner: banner
      }
    },
    params () {
      const { catId, page } = this
      return {
        catId,
        page
      }
    }
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banner = val.banner[this.pageId][0].img
        let cats = val.product.category.cn
        let firstIds = []
        let firstCategorys = []
        for (let i in cats) {
          firstIds.push(cats[i].id)
          firstCategorys.push(
            { key: i, id: cats[i].id, name: cats[i].name }
          )
          this.firstCategory = firstIds.indexOf(parseInt(this.p1))
        }
        this.firstIds = firstIds
        this.firstCategorys = firstCategorys
      },
      deep: true
    },
    p1: {
      handler: function (val, oldVal) {
        this.getCategory(val)
      },
      deep: true
    },
    catId: {
      handler: function (val, oldVal) {
        this.getBanner(val)
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        let catId = val.catId
        let page = val.page
        this.getProducts(page, catId)
      },
      deep: true
    }
  },
  methods: {
    getCategory (catId) {
      this.https.get('product_categorys', { pid: catId }).then(response => {
        if (response.code === 0) {
          this.breadcrumbs = [
            { text: '首页', disabled: false, href: '/' },
            { text: '产品系统', disabled: true }
          ]
          if (response.data[0]._child) {
            this.secondCategorys = response.data[0]._child
            for (let i in this.secondCategorys) {
              this.secondIds.push(this.secondCategorys[i].id)
            }
            this.secondCategory = this.secondIds.indexOf(parseInt(this.p2))
            if (!this.p2) {
              this.catId = this.secondCategorys[0].id
            }
          }
        }
      })
    },
    getBanner (id) {
      this.https.get('product_category', { id: id }).then(response => {
        if (response.code === 0) {
          if (response.data.cat.banner) {
            this.banner = response.data.cat.banner
          }
        }
      })
    },
    getProducts (page, cid) {
      this.https.get('products', { page: page, cid: cid, pageSize: 200 }).then(response => {
        if (response.code === 0) {
          this.products = response.data.data
          this.totalPage = response.data.last_page
          this.page = page
        }
      })
    }
  }
}
</script>
<style lang="css">
  @import '../css/products.css';
</style>
